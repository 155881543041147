export const menuItems = [
    {
     title: "About"
    },
    {
     title: "Roadmap"
    },
    {
     title: "Utility"
    },
    {
     title: "Team"
    },
    // {
    //  title: "Community",
    //  submenu: [
    //      {title: "Twitter", link: "/comingsoon"},
    //      {title: "IG", link: "/comingsoon"},
    //      {title: "Discord", link: "/comingsoon"},

    //  ]
    // }
];