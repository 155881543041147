import React from 'react'

const Terms = () => {
  return (
    <div style={{margin:'auto'}}>
        <embed className='pdf' src="/config/images/terms_conditions.pdf" width="800px" height="2100px"/>
    </div>
  )
}

export default Terms